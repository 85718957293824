<template>
  <CCard>
    <CCardHeader>
      <h2>Defined Workflows</h2>
    </CCardHeader>
    <CCardBody>
      <CDataTable :items="defs" :fields="fields" striped>
        <template #name="{ item }">
          <td class="align-middle">
            <router-link
              :to="{
                name: 'view-workflow-def',
                params: { workflowDefName: item.name },
              }"
              >{{ item.name }}</router-link
            >
          </td>
        </template>
        <template #title="{ item }">
          <td class="align-middle">
            <router-link
              :to="{
                name: 'view-workflow-def',
                params: { workflowDefName: item.name },
              }"
              >{{ item.title }}</router-link
            >
          </td>
        </template>
        <template #workflowType="{ item }">
          <td class="align-middle">{{ item.workflowType || '--' }}</td>
        </template>
        <template #updateTime="{ item }">
          <td class="align-middle">
            {{ $format.formatTimestamp(item.updateTime || item.createTime) }}
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>
<script>
import { entityLoadingMixin } from '@/mixins/entity-loading-mixin';
const FIELDS = [
  {
    key: 'name',
    label: 'name',
  },
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'workflowType',
    label: 'Type',
  },
  {
    key: 'version',
    label: 'Version',
  },
  {
    key: 'updateTime',
    label: 'Updated At',
  },
];

export default {
  name: 'WorkflowDefinitionList',
  mixins: [entityLoadingMixin],
  computed: {
    defs() {
      return this.fetchedData || [];
    },
    fields() {
      return FIELDS;
    },
  },
  methods: {
    fetchData(params) {
      return this.$store.dispatch('workflows/fetchWorkflowDefinitions');
    },
  },
};
</script>
